@import 'css/_variables.scss';

.ScrollUpButton__Container {
  height: 64px;
  position: fixed;
  bottom: 43%;
  width: 64px;
  transition: opacity 0.5s ease-in-out 0s, right;
  cursor: pointer;
  opacity: 0;
  right: -50px;
  z-index: 1000;
  outline: none;
  border: none;
}

.ScrollUpButton__Toggled {
  height: 64px;
  position: fixed;
  bottom: 43%;
  width: 64px;
  transition: opacity 0.5s ease-in-out 0s, right;
  cursor: pointer;
  opacity: 0.8;
  right: 20px;
  z-index: 1000;
}

.ScrollUpButton__Toggled:hover {
  opacity: 1;
}

@media (max-width: 768px) {
  .ScrollUpButton__Container,
  .ScrollUpButton__Toggled,
  .icon-scroll {
    height: 40px !important;
    width: 40px !important;
  }
}
